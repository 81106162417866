import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

export default function Typewriter(props) {
  const { text, delay } = props;
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText(prevText => prevText + text[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, delay);
  
      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);

  return <span>{currentText}</span>;
}

Typewriter.propTypes = {
  text: PropTypes.string.isRequired,
  delay: PropTypes.number
};

Typewriter.defaultProps = {
  delay: 100 // or any default value you want for delay
};
