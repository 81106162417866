import { React, useContext } from "react";
import { Modal } from "antd";
import { PageContext } from "../../Context/pageContext";
import { Space } from 'antd';
import PropTypes from "prop-types";
import { CodeBlock } from 'react-code-blocks';

export function ModalCode(props) {
  const { isModalCodeOpen, setIsModalCodeOpen } = useContext(PageContext);

  const handleOk = () => {
    setIsModalCodeOpen(false);
  };

  const handleCancel = () => {
    setIsModalCodeOpen(false);
  };

  return (
    <Modal open={isModalCodeOpen} onOk={handleOk}
      onCancel={handleCancel} footer={[]} centered width="50em" z-index={0}
      styles={{ overflowY: 'scroll', height: "auto", width: "auto" }}>
      

        <div className="row">
          <h5>SQL</h5>
          <Space direction="vertical">
            <CodeBlock 
            language={'sql'} 
            showLineNumbers={false}
            text={props.generated_code}/>
          </Space>

        </div>
    </Modal>
  )
}
ModalCode.propTypes = {
  generated_code: PropTypes.string
}

