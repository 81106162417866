import React, { useContext, useEffect, useRef, useState } from "react";
import { PageContext } from "../../Context/pageContext";
import { Button } from 'antd';
import CoffeLoader from "../../Components/Loader/coffeLoader";
import Typewriter from "../../Components/Typewriter/typewriter";
import ReactMarkdown from "react-markdown";


export default function ImageGen() {
    const delay = 30;
    const { loadingResponse } = useContext(PageContext);
    const { llmResponseImage, setLlmResponseImage } = useContext(PageContext);
    const { file, setFile } = useContext(PageContext);
    const { chatHistoryImage, setChatHistoryImage } = useContext(PageContext);

    const [initialAIMessage] = useState("Ciao! Per iniziare carica un immagine");
    const [showAskQuestion, setShowAskQuestion] = useState(false);
    const [showRetry, setShowRetry] = useState(false);

    const chatEndRef = useRef(null);

    useEffect(() => {
        setShowAskQuestion(true);
    }, [file])
    useEffect(() => {
        if(llmResponseImage)
            chatEndRef.current.scrollIntoView({ behavior: "smooth" });
        if (file)
            setShowRetry(true);
    }, [llmResponseImage]);

    function handleNewSearch() {
        setShowRetry(false);
        setShowAskQuestion(false);
        setLlmResponseImage(false);
        setFile(undefined);
        setChatHistoryImage([]);
    }

    return (
        <>
            <div className="container mt-5 scrollable-div">
                <div className="d-flex flex-row justify-content-start">
                    <div className="group-background-ai group-background">
                        <Typewriter text={initialAIMessage ? initialAIMessage : ""} delay={delay} />
                    </div>
                </div>

                {file &&
                    <div className="d-flex flex-row justify-content-end mt-3">
                        <div className=" group-background">
                            <img src={URL.createObjectURL(file)} alt="Preview" style={{
                                maxWidth: '500px',
                                maxHeight: '500px',
                                width: 'auto',
                                height: 'auto'
                            }} />
                        </div>
                    </div>
                }

                {file && (
                    <>
                        {showAskQuestion &&
                            <div className="d-flex flex-row justify-content-start mt-3">
                                <div className="group-background-ai group-background">
                                    <Typewriter text="Ottimo! Che cosa vuoi chiedermi?" delay={50} />
                                </div>
                            </div>
                        }
                    </>
                )}

                {chatHistoryImage?.length > 0 &&
                    <>
                        {chatHistoryImage.map((message, index) => (
                            <div
                                key={index} ref={chatEndRef}
                                className={`d-flex flex-row ${message.human ? 'justify-content-end' : 'justify-content-start'} mt-3`}
                            >
                                <div className={`group-background-${message.human ? 'req-documentale' : 'response'}`}>
                                    {
                                        message.human
                                            ? <Typewriter text={message.human.msg} delay={delay} />
                                            :
                                            <>
                                                <div className="d-flex flex-row justify-content-center mt-3">
                                                    <div className="group-background">
                                                        <div className="flex-row d-flex justify-content-start">
                                                            <ReactMarkdown className="markdown-content">
                                                            {message.ai.msg ? message.ai.msg : ""}
                                                            </ReactMarkdown>
                                                            {/*<Typewriter text={message.ai.msg ? message.ai.msg : ""} delay={delay} />*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                        ))}
                    </>
                }

                {/*
                    llmResponseImage &&
                    <div className="d-flex flex-row justify-content-start">
                    <div className="group-background-ai group-background">
                        {llmResponseImage}
                    </div>
                </div>
            */}
                <div className="d-flex flex-row mt-2">
                    <div className="">
                        {loadingResponse ? <CoffeLoader text={"Sto analizzando"} /> : null}
                        {showRetry &&
                            <>
                                <div className="d-flex flex-row justify-content-start mt-3">
                                    <div className="group-background-ai group-background">
                                        <Typewriter text="Spero che queste informazioni ti siano state d'aiuto" delay={delay} />
                                    </div>
                                </div>
                                <div className="d-flex flex-row justify-content-start mt-3">
                                    <div className="group-background-ai">
                                        <Button className="ant-btn-primary" onClick={() => handleNewSearch()}>Nuova Ricerca</Button>
                                        <Button className="btn-continua" onClick={() => setShowRetry(false)}>Continua</Button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div ref={chatEndRef} />
            </div>
        </>
    );
}
