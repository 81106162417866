// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.float-button-selected{
    color: rgb(45, 220, 255) !important;
    background-color: rgba(150, 170, 174, 0.1) !important;
}
`, "",{"version":3,"sources":["webpack://./src/css/float-menu.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,qDAAqD;AACzD","sourcesContent":[".float-button-selected{\n    color: rgb(45, 220, 255) !important;\n    background-color: rgba(150, 170, 174, 0.1) !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
