// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  background-color: rgb(144, 154, 144);
}

.icon-sidebar:hover {
  background-color: black !important;
  cursor: pointer;
}

.nav-link {
  padding: 0 !important;
}

.sidebar-custom {
  max-width: 300px !important;
  flex: none !important;
}`, "",{"version":3,"sources":["webpack://./src/css/side-bar.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,kCAAkC;EAClC,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,2BAA2B;EAC3B,qBAAqB;AACvB","sourcesContent":[".sidebar {\n  background-color: rgb(144, 154, 144);\n}\n\n.icon-sidebar:hover {\n  background-color: black !important;\n  cursor: pointer;\n}\n\n.nav-link {\n  padding: 0 !important;\n}\n\n.sidebar-custom {\n  max-width: 300px !important;\n  flex: none !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
