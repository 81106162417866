import React, { useContext, useEffect, useRef, useState } from "react";
import { FileOutlined, FilePdfOutlined, FilePptOutlined, FileWordOutlined } from '@ant-design/icons';
import { PageContext } from "../../Context/pageContext";
import { Button } from 'antd';

import CoffeLoader from "../../Components/Loader/coffeLoader";
import Typewriter from "../../Components/Typewriter/typewriter";
import { Robot, UserCircle } from "@phosphor-icons/react";

export default function DocuGenCustom() {
    const delay = 30;
    const CATEGORIES = ['GFK', 'MINTEL', 'KANTAR', 'EUROMONITOR', "IRI"]
    const { llmResponseDocumentale, setLlmResponseDocumentale } = useContext(PageContext);
    const { setLastPromptDocumentale } = useContext(PageContext);
    const { loadingResponse } = useContext(PageContext);
    const { referencedResponse, setReferencedResponse } = useContext(PageContext);
    const { activeFilters, setActiveFilters } = useContext(PageContext);

    const [listReferences, setListReferences] = useState(undefined);
    const [metadataWarn, setMetadataWarn] = useState(undefined);
    const [initialAIMessage, setInitialAIMessage] = useState("Ciao! Per iniziare seleziona un filtro per la tua ricerca");
    const [selectedFilter, setSelectedFilter] = useState(undefined);
    const [pressOK, setPressOK] = useState(false);
    const [showButtons, setShowButtons] = useState(false); // New state for showing buttons
    const [showAskQuestion, setShowAskQuestion] = useState(false);
    const [showRetry, setShowRetry] = useState(false);
    const { chatHistoryDocumentale, setChatHistoryDocumentale } = useContext(PageContext);

    const chatEndRef = useRef(null);

    useEffect(() => {
        setMetadataWarn(undefined);

        if (llmResponseDocumentale && llmResponseDocumentale['response']) {
            console.log("qui")
            let tmp = llmResponseDocumentale['response']["summary"]
            if (tmp == "")
                setReferencedResponse("Non è stato possibile riassumere l'informazione")
            else
                setReferencedResponse(llmResponseDocumentale['response']["summary"])
            const documentsArray = Object.entries(llmResponseDocumentale['response']["documents"]).map(([key, document]) => ({
                number: key,
                folder: document.folder,
                page: document.page,
                link: document.link,
                preview: document && document.preview ? document.preview.substring(0, 100) + " ..." : "Preview not available."
            }));

            setListReferences(documentsArray);

            if (llmResponseDocumentale['metadata_warn']) {
                setMetadataWarn(llmResponseDocumentale['metadata_warn'])
            }

            console.log(documentsArray)
        }

    }, [llmResponseDocumentale])

    const handleFilterClick = (filter) => {
        if (filter === "Tutti") {
            if (activeFilters.includes("Tutti")) {
                setActiveFilters([]);
            } else {
                setActiveFilters(CATEGORIES);
            }
        } else {
            setActiveFilters((prevFilters) => {
                const updatedFilters = prevFilters.includes(filter)
                    ? prevFilters.filter((f) => f !== filter)
                    : [...prevFilters, filter];
                if (updatedFilters.length === CATEGORIES.length) {
                    updatedFilters.push("Tutti");
                } else {
                    const index = updatedFilters.indexOf("Tutti");
                    if (index > -1) {
                        updatedFilters.splice(index, 1);
                    }
                }
                return updatedFilters;
            });
        }
        setSelectedFilter(activeFilters.join(", "));
    };

    const handlePressOk = () => {
        setPressOK(true);
        setSelectedFilter(activeFilters.join(", "));
        setShowButtons(false);
        setShowAskQuestion(false); // Reset the delayed div state
        setTimeout(() => setShowAskQuestion(true), 500);
    };

    const handleNewSearch = () => {
        setInitialAIMessage(""); // Clear the initial message
        setPressOK(false);
        setActiveFilters([]);
        setSelectedFilter(undefined);
        setReferencedResponse(undefined);
        setLlmResponseDocumentale(undefined);
        setLastPromptDocumentale(undefined);
        setShowButtons(false);
        setShowAskQuestion(false);
        setShowRetry(false);
        setChatHistoryDocumentale([]);


        setInitialAIMessage("Ciao! Per iniziare seleziona un filtro per la tua ricerca"); // Set the initial message again
        setShowButtons(true);

    };

    useEffect(() => {
        if (chatEndRef.current)
            chatEndRef.current.scrollIntoView({ behavior: "smooth" });

        if (referencedResponse)
            setShowRetry(true);

    }, [referencedResponse]);

    useEffect(() => {
        setInitialAIMessage("Ciao! Per iniziare seleziona un filtro per la tua ricerca")
        setTimeout(() => setShowButtons(true), 2700);
    }, []);

    return (
        <>
            <div className="container mt-5 scrollable-div">
                <div className="d-flex flex-row justify-content-start">
                    <div className="icon-ai"><Robot size={22} /></div>
                    <div className="group-background-ai group-background">
                        <Typewriter text={initialAIMessage ? initialAIMessage : ""} delay={delay} />
                    </div>
                </div>

                {showButtons && !pressOK ? (
                    <div>
                        <div className="d-flex flex-row justify-content-start mt-3">
                            <div className="space-buttons">
                                {["Tutti", ...CATEGORIES].map(filter => (
                                    <Button
                                        key={filter}
                                        className={`btn-filter ant-btn-primary-filter ${activeFilters.includes(filter) ? 'active' : ''}`}
                                        onClick={() => handleFilterClick(filter)}
                                    >
                                        {filter.replace('_', ' ')}
                                    </Button>
                                ))}
                            </div>
                        </div>

                        {activeFilters.length > 0 && (
                            <div className="d-flex flex-row justify-content-start mt-3">
                                <div className="group-background-ai space-buttons">
                                    <Button className="ant-btn-primary" onClick={handlePressOk}>Conferma</Button>
                                </div>
                            </div>
                        )}
                    </div>

                )
                    : null
                }

                {pressOK && (
                    <>
                        <div className="d-flex flex-row justify-content-end mt-3">
                            <div className="group-background-human">
                                {selectedFilter}
                            </div>
                            <div className="icon-human"><UserCircle size={22} /></div>
                        </div>
                        {showAskQuestion &&
                            <div className="d-flex flex-row justify-content-start mt-3">
                                <div className="icon-ai"><Robot size={22} /></div>
                                <div className="group-background-ai group-background">
                                    <Typewriter text="Ottimo! Che cosa vuoi chiedermi?" delay={50} />
                                </div>
                            </div>
                        }
                    </>
                )}


                {chatHistoryDocumentale?.length > 0 ? (
                    <>
                        {chatHistoryDocumentale.map((message, index) => (
                            <div
                                key={index} ref={chatEndRef}
                                className={`d-flex flex-row ${message.human ? 'justify-content-end' : 'justify-content-start'} mt-3`}
                            >
                                {message.ai
                                    && <div className="icon-ai"><Robot size={22} /></div>
                                }
                                <div className={`group-background-${message.human ? 'human' : 'ai'}`}>
                                    {message.human ? (
                                        <Typewriter text={message.human.msg} delay={delay} />
                                    ) : (
                                        <>
                                            <div className="group-background">
                                                <div dangerouslySetInnerHTML={{ __html: message.ai.msg }} />
                                                {metadataWarn ? (
                                                    <>
                                                        <br /><br />
                                                        <p style={{ color: 'red', fontSize: 'small' }}>
                                                            <Typewriter text="*Non è stato possibile filtrare i risultati. La ricerca è stata fatta su tutte le categorie. " delay={delay} />
                                                        </p>
                                                    </>
                                                ) : null}
                                            </div>
                                            {message.ai.documents?.length > 0 && (
                                                <div className="wrapper">
                                                    <div className="group-background docu-list-wrapper mt-3">
                                                        {message.ai.documents.map((document, index) => {
                                                            const urlWithoutFragment = document.link.split('#')[0];
                                                            const parts = urlWithoutFragment.split('.');
                                                            const fileExtension = parts.length > 1 ? parts.pop().toLowerCase() : '';
                                                            let fileIcon;

                                                            if (fileExtension === 'pdf') {
                                                                fileIcon = <FilePdfOutlined style={{ color: "#ff3d3d" }} />;
                                                            } else if (fileExtension === 'doc' || fileExtension === 'docx') {
                                                                fileIcon = <FileWordOutlined style={{ color: "blue" }} />;
                                                            } else if (fileExtension === 'pptx') {
                                                                fileIcon = <FilePptOutlined style={{ color: "orange" }} />;
                                                            } else {
                                                                fileIcon = <FileOutlined />;
                                                            }

                                                            return (
                                                                <div className="document-list mt-2" key={index}>
                                                                    <p>
                                                                        {fileIcon}<b> Documento {document.number}: {document.link.split('/').pop()} </b>
                                                                    </p>
                                                                    <p><u>Cartella:</u> {document.folder}</p>
                                                                    <p><u>Pagina:</u> {document.page}</p>
                                                                    <p dangerouslySetInnerHTML={{ __html: document.preview.substring(0, 200) + " ..." }} />
                                                                    <div className="button-container">
                                                                        <Button type="primary" className="ant-btn-primary" onClick={() => window.open(document.link, '_blank')}>
                                                                            Visualizza
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {
                                    message.human
                                    && <div className="icon-human"><UserCircle size={22} /></div>
                                }
                            </div>
                        ))}
                    </>
                ) : null}

                <div className="d-flex flex-row mt-2">
                    <div className="">
                        {loadingResponse ? <CoffeLoader text={"Sto analizzando i documenti"} /> : null}
                        {showRetry &&
                            <>
                                <div className="d-flex flex-row justify-content-start mt-3">
                                <div className="icon-ai"><Robot size={22} /></div>
                                    <div className="group-background-ai group-background">
                                        <Typewriter text="Spero che queste informazioni ti siano state d'aiuto" delay={delay} />
                                    </div>
                                </div>
                                <div className="d-flex flex-row justify-content-start mt-3">
                                    <div className="group-background-ai space-buttons">
                                        <Button className="ant-btn-primary" onClick={() => handleNewSearch()}>Nuova Ricerca</Button>
                                        <Button className="btn-continua" onClick={() => setShowRetry(false)}>Continua</Button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div ref={chatEndRef} />
            </div>
        </>
    );
}
