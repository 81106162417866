// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdown-content {
    font-size: medium; /* Default font size */
  }

  .markdown-content h2 {
    font-size: medium; /* Font size for h2 headers */
    font-weight: 200;
  }

  .markdown-content strong {
    font-size: medium; /* Font size for bold text */
  }

  .markdown-content code {
    font-size: 14px; /* Font size for code blocks */
    background-color: #f0f0f0; /* Background color for code blocks */
    padding: 2px 4px; /* Padding for code blocks */
    border-radius: 4px; /* Rounded corners for code blocks */
  }

  .markdown-content table {
    width: 100%; /* Make the table take full width */
    border-collapse: collapse; /* Collapse table borders */
    margin-top: 10px; /* Margin at the top */
  }

  .markdown-content table, .markdown-content th, .markdown-content td {
    border: 1px solid #ddd; /* Border for table and cells */
  }

  .markdown-content th, .markdown-content td {
    padding: 8px; /* Padding for table cells */
    text-align: left; /* Align text to the left */
  }`, "",{"version":3,"sources":["webpack://./src/css/table-custom.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB,EAAE,sBAAsB;EAC3C;;EAEA;IACE,iBAAiB,EAAE,6BAA6B;IAChD,gBAAgB;EAClB;;EAEA;IACE,iBAAiB,EAAE,4BAA4B;EACjD;;EAEA;IACE,eAAe,EAAE,8BAA8B;IAC/C,yBAAyB,EAAE,qCAAqC;IAChE,gBAAgB,EAAE,4BAA4B;IAC9C,kBAAkB,EAAE,oCAAoC;EAC1D;;EAEA;IACE,WAAW,EAAE,mCAAmC;IAChD,yBAAyB,EAAE,2BAA2B;IACtD,gBAAgB,EAAE,sBAAsB;EAC1C;;EAEA;IACE,sBAAsB,EAAE,+BAA+B;EACzD;;EAEA;IACE,YAAY,EAAE,4BAA4B;IAC1C,gBAAgB,EAAE,2BAA2B;EAC/C","sourcesContent":[".markdown-content {\n    font-size: medium; /* Default font size */\n  }\n\n  .markdown-content h2 {\n    font-size: medium; /* Font size for h2 headers */\n    font-weight: 200;\n  }\n\n  .markdown-content strong {\n    font-size: medium; /* Font size for bold text */\n  }\n\n  .markdown-content code {\n    font-size: 14px; /* Font size for code blocks */\n    background-color: #f0f0f0; /* Background color for code blocks */\n    padding: 2px 4px; /* Padding for code blocks */\n    border-radius: 4px; /* Rounded corners for code blocks */\n  }\n\n  .markdown-content table {\n    width: 100%; /* Make the table take full width */\n    border-collapse: collapse; /* Collapse table borders */\n    margin-top: 10px; /* Margin at the top */\n  }\n\n  .markdown-content table, .markdown-content th, .markdown-content td {\n    border: 1px solid #ddd; /* Border for table and cells */\n  }\n\n  .markdown-content th, .markdown-content td {\n    padding: 8px; /* Padding for table cells */\n    text-align: left; /* Align text to the left */\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
