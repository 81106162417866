import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './css/style.css';
import Navbar from './Components/Navbar/navbar';
import CustomFooter from './Components/Footer/footer';
import { PageContext } from './Context/pageContext';
import { Layout } from 'antd';
//import DocumentaleGen from './Pages/DocumentaleGen/docuGen'; // google black box
import Cookies from 'universal-cookie';
import SideBar from './Components/Navbar/sidebar';
import DocuGenCustom from './Pages/DocumentaleGen/docuGenMetadataFilter';
import DataGenChat from './Pages/DataGen/dataGenChat';
import DocuFooter from './Components/Footer/footer_documentale';
import ImageGen from './Pages/ImageGen/imageGen';
import ImageFooter from './Components/Footer/footer_images';

const App = () => {
  const cookies = new Cookies();

  const { dataMode, setDataMode } = useContext(PageContext);
  const { imageMode, setImageMode } = useContext(PageContext);
  const { documentaleMode, setDocumentaleMode } = useContext(PageContext);
  const { setLookerMode } = useContext(PageContext);
  const { Content } = Layout;

  useEffect(() => {
    console.log("chartMode " + imageMode)
    console.log("dataMode " + dataMode)
    console.log("documentaleMode " + documentaleMode)
    let state = cookies.get("state")
    if (state) {
      setDataMode(state["dataMode"]);
      setImageMode(state["imageMode"]);
      setDocumentaleMode(state["documentaleMode"]);
      setLookerMode(state["lookerMode"]);
    }else{
    setDataMode(true);
    setImageMode(false);
    setDocumentaleMode(false);
    setLookerMode(false);
    }
  }, [])

  return (
    <>
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <SideBar />

        <Layout className='test'>

          <Navbar />

          <Content
            style={{
              margin: '0 16px',
            }}
          >
            <Routes key="routes">
              <Route key={'data-gen'} path="/" element={<DataGenChat />} />
              <Route key={'data-gen'} path="/data-gen" element={<DataGenChat />} />
              <Route key={'image-gen'} path="/image-gen" element={<ImageGen />} />
              <Route key={'docu-gen'} path="/docu-gen" element={<DocuGenCustom />} />
            </Routes>

          </Content>

          {
            [
              dataMode && <CustomFooter />,
              documentaleMode && <DocuFooter />,
              imageMode && <ImageFooter />
            ]
          }

        </Layout>
      </Layout>
    </>
  );
};
export default App;