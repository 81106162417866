// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.test{
  overflow-y: hidden;
}
.h3,
h3 {
  font-size: 1.4rem !important;
  /*text-transform: uppercase !important;*/
  font-weight: 300 !important;
  /*background-color: rgba(150, 170, 174, 0.3);*/
  display: inline-block;
}

.navbar {
  font-size: small;
  background-color: white !important;
  border-radius: 0.25rem;
  justify-content: space-around !important;
  z-index: 99 !important;
}

.scrollable-div {
  max-height: 70vh; /* Set the desired maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 80%;
}
`, "",{"version":3,"sources":["webpack://./src/css/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;;EAEE,4BAA4B;EAC5B,wCAAwC;EACxC,2BAA2B;EAC3B,8CAA8C;EAC9C,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,kCAAkC;EAClC,sBAAsB;EACtB,wCAAwC;EACxC,sBAAsB;AACxB;;AAEA;EACE,gBAAgB,EAAE,mCAAmC;EACrD,gBAAgB,EAAE,8BAA8B;EAChD,UAAU;AACZ","sourcesContent":[".test{\n  overflow-y: hidden;\n}\n.h3,\nh3 {\n  font-size: 1.4rem !important;\n  /*text-transform: uppercase !important;*/\n  font-weight: 300 !important;\n  /*background-color: rgba(150, 170, 174, 0.3);*/\n  display: inline-block;\n}\n\n.navbar {\n  font-size: small;\n  background-color: white !important;\n  border-radius: 0.25rem;\n  justify-content: space-around !important;\n  z-index: 99 !important;\n}\n\n.scrollable-div {\n  max-height: 70vh; /* Set the desired maximum height */\n  overflow-y: auto; /* Enable vertical scrolling */\n  width: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
