export function cleanCsvOutput(text) {
    if (text)
        return text.replace(/[|-]/g, "");
    else
        return text;
}

export function cleanString(text) {
    if (text)
        return text.replace(/[\n\t\r]/g, "");
    else
        return text;
}