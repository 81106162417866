// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe {
    height: 100%;
    padding-left: 5em;
    padding-right: 5em;
    padding-bottom: 5em;
    width: 100% !important;
  }
  
  .h_iframe {
    width: 100vw !important;
  }`, "",{"version":3,"sources":["webpack://./src/css/iframe-custom.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;EACxB;;EAEA;IACE,uBAAuB;EACzB","sourcesContent":["iframe {\n    height: 100%;\n    padding-left: 5em;\n    padding-right: 5em;\n    padding-bottom: 5em;\n    width: 100% !important;\n  }\n  \n  .h_iframe {\n    width: 100vw !important;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
