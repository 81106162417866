import React from "react";
import PropTypes from "prop-types";
import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";

export function ExportToCSV(props) {

  const handleButtonClick = () => {
    // Assuming props.data is a string containing CSV data
    const csvData = props.data;

    // Create a Blob from the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Generate a download link using the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor element
    const a = document.createElement('a');

    // Set its attributes
    a.href = url;
    a.download = 'exported_data.csv';

    // Trigger a click on the anchor element to initiate the download
    document.body.appendChild(a);
    a.click();

    // Clean up by revoking the object URL
    URL.revokeObjectURL(url);

    // Remove the anchor element from the DOM
    document.body.removeChild(a);
  };

  return (
    <Button type="primary"
      icon={<DownloadOutlined />}
      size={"middle"}
      onClick={handleButtonClick}
      hidden={props.hidden}
      disabled={props.hidden}>
      Scarica
    </Button>
  );
}

ExportToCSV.propTypes = {
  data: PropTypes.any,
  hidden: PropTypes.bool
}