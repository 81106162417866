// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divTableHeading {
    background-color: rgb(193 54 44 / 17%) !important;
    color: rgb(15, 4, 4) !important;
  }
  
  .activePage {
    background-color: rgb(193 54 44 / 17%) !important;
  }
  
  .divTableBody {
    border-radius: 0.55rem !important;
  }

  .divTableCell{
    border: 0.5px solid rgb(82 36 23) !important;
  }

  .tableWithCSV{
    margin: 0 !important;
  }`, "",{"version":3,"sources":["webpack://./src/css/dynamic-table.css"],"names":[],"mappings":"AAAA;IACI,iDAAiD;IACjD,+BAA+B;EACjC;;EAEA;IACE,iDAAiD;EACnD;;EAEA;IACE,iCAAiC;EACnC;;EAEA;IACE,4CAA4C;EAC9C;;EAEA;IACE,oBAAoB;EACtB","sourcesContent":[".divTableHeading {\n    background-color: rgb(193 54 44 / 17%) !important;\n    color: rgb(15, 4, 4) !important;\n  }\n  \n  .activePage {\n    background-color: rgb(193 54 44 / 17%) !important;\n  }\n  \n  .divTableBody {\n    border-radius: 0.55rem !important;\n  }\n\n  .divTableCell{\n    border: 0.5px solid rgb(82 36 23) !important;\n  }\n\n  .tableWithCSV{\n    margin: 0 !important;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
