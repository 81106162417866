export function findMaxKeyAndIncrement(array) {
    // Use reduce to find the element with the maximum key
    const maxKeyElement = array.reduce((max, current) => {
        const currentKey = parseInt(current.key, 10);
        const maxKey = parseInt(max.key, 10);

        return currentKey > maxKey ? current : max;
    }, { key: '100' }); // Initialize with a minimum key

    // Increment the maximum key by 1
    const maxKey = parseInt(maxKeyElement.key, 10);
    const nextKey = maxKey + 1;

    return nextKey;
}