// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    border-radius: 0.25rem;
    border: 1px solid rgba(66, 33, 24,0.3);
    /*box-shadow: 8px 5px 5px rgba(0, 0, 0, 0.05);*/
    height: 10em;   
    margin: 0.5em;  
    text-align: center;
  }

  .card:hover {
    cursor: pointer;
    border-color:rgba(66, 33, 24,0.3);
    background-color: rgba(66, 33, 24,0.1);
  }
  
  .card-body{
    display: flex;           /* Attiva Flexbox */
    align-items: center;
  }
  
  .tooltip-documentale {
    position: absolute;
    bottom: 3em;
  }
  
  .center-tooltip{
    display: flex;
    justify-content: center; /* Centra orizzontalmente */
    align-items: center; /* Centra verticalmente */
    height: 50vh; 
  }

  .tooltip-container {
    padding-bottom: 1em;
    justify-content: center;
    align-items: center;
  }
  
  .tooltip-bold {
    font-size: medium;
    font-weight:500;
  }
  
  .tooltip-light {
    font-size: medium;
    font-weight: 100;
  }
  
  .card:active {
    background-color: rgba(66, 33, 24,0.1);
  }

  .center-title-tooltip{
    display: flex;
    justify-content: center; /* Centra orizzontalmente */
    align-items: center; /* Centra verticalmente */
    height: 10vh;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/css/tooltip.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,sCAAsC;IACtC,+CAA+C;IAC/C,YAAY;IACZ,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,iCAAiC;IACjC,sCAAsC;EACxC;;EAEA;IACE,aAAa,YAAY,mBAAmB;IAC5C,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,uBAAuB,EAAE,2BAA2B;IACpD,mBAAmB,EAAE,yBAAyB;IAC9C,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,sCAAsC;EACxC;;EAEA;IACE,aAAa;IACb,uBAAuB,EAAE,2BAA2B;IACpD,mBAAmB,EAAE,yBAAyB;IAC9C,YAAY;EACd","sourcesContent":[".card {\n    border-radius: 0.25rem;\n    border: 1px solid rgba(66, 33, 24,0.3);\n    /*box-shadow: 8px 5px 5px rgba(0, 0, 0, 0.05);*/\n    height: 10em;   \n    margin: 0.5em;  \n    text-align: center;\n  }\n\n  .card:hover {\n    cursor: pointer;\n    border-color:rgba(66, 33, 24,0.3);\n    background-color: rgba(66, 33, 24,0.1);\n  }\n  \n  .card-body{\n    display: flex;           /* Attiva Flexbox */\n    align-items: center;\n  }\n  \n  .tooltip-documentale {\n    position: absolute;\n    bottom: 3em;\n  }\n  \n  .center-tooltip{\n    display: flex;\n    justify-content: center; /* Centra orizzontalmente */\n    align-items: center; /* Centra verticalmente */\n    height: 50vh; \n  }\n\n  .tooltip-container {\n    padding-bottom: 1em;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .tooltip-bold {\n    font-size: medium;\n    font-weight:500;\n  }\n  \n  .tooltip-light {\n    font-size: medium;\n    font-weight: 100;\n  }\n  \n  .card:active {\n    background-color: rgba(66, 33, 24,0.1);\n  }\n\n  .center-title-tooltip{\n    display: flex;\n    justify-content: center; /* Centra orizzontalmente */\n    align-items: center; /* Centra verticalmente */\n    height: 10vh;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
