/**
* Retrieve data from BQ
*/
export async function getInsight(prompt) {
  console.debug("[BACKEND REQUEST] /gen-ai/get_insight");
console.log(process.env.NODE_ENV.REACT_APP_BACKEND_ADDRESS)
  return fetch(process.env.REACT_APP_BACKEND_ADDRESS + "/gen-ai/get_insight", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ "prompt": prompt })

  })
    .then(function (response) {
      if (response.ok) {
        return response.text();
      } else {
        throw new Error('Request failed with status code ' + response.status);
      }
    })
    .then(result => {
      // Parse the JSON string to obtain the object
      console.log(JSON.parse(result))
      return JSON.parse(result);
    })
    .catch(error => {
      console.error("Error:", error);
      return [];
    });
}

/**
* Retrieve data from BQ
*/
export async function removeMessage(message_index) {
  console.debug("[BACKEND REQUEST] /gen-ai/dislike");
console.log(process.env.NODE_ENV.REACT_APP_BACKEND_ADDRESS)
  return fetch(process.env.REACT_APP_BACKEND_ADDRESS + "/gen-ai/dislike", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ "message_index": message_index })

  })
    .then(function (response) {
      if (response.ok) {
        return response.text();
      } else {
        throw new Error('Request failed with status code ' + response.status);
      }
    })
    .then(result => {
      // Parse the JSON string to obtain the object
      console.log(JSON.parse(result))
      return JSON.parse(result);
    })
    .catch(error => {
      console.error("Error:", error);
      return [];
    });
}

/**
* document_search
*/
export async function getDocumentInsight(prompt, filters) {
  console.debug("[BACKEND REQUEST] /gen-ai/document_search");
console.log(process.env.NODE_ENV.REACT_APP_BACKEND_ADDRESS)
  return fetch(process.env.REACT_APP_BACKEND_ADDRESS + "/gen-ai/document_search", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ "prompt": prompt, "filters": filters})

  })
    .then(function (response) {
      if (response.ok) {
        return response.text();
      } else {
        throw new Error('Request failed with status code ' + response.status);
      }
    })
    .then(result => {
      // Parse the JSON string to obtain the object
      console.log(JSON.parse(result))
      return JSON.parse(result);
    })
    .catch(error => {
      console.error("Error:", error);
      return [];
    });
  }

  export async function getImageInsight(file, message) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("prompt", message);

    console.debug("[BACKEND REQUEST] /gen-ai/image_insight");
    console.log(process.env.REACT_APP_BACKEND_ADDRESS);

    return fetch(process.env.REACT_APP_BACKEND_ADDRESS + "/gen-ai/image_insight", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(
            "Request failed with status code " + response.status
          );
        }
      })
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((error) => {
        console.error("Error:", error);
        return { error: error.message };
      });
  }

  export async function resetChat() {
    console.debug("[BACKEND REQUEST] /gen-ai/reset_chat");
    console.log(process.env.REACT_APP_BACKEND_ADDRESS);

    return fetch(process.env.REACT_APP_BACKEND_ADDRESS + "/gen-ai/reset_chat", {
      method: "POST",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(
            "Request failed with status code " + response.status
          );
        }
      })
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((error) => {
        console.error("Error:", error);
        return { error: error.message };
      });
  }