// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-holder {
    width:100%;
    height:100%;
    
    display:flex;
    align-items:center;
    
  }
  .loader {
    position:relative;
    z-index:1;
  }
  #loader-1{
    animation: bounce 1.6s ease-in-out 0.1s infinite;
    margin: 0.2em;
  }
  #loader-2{
    animation: bounce 1.6s ease-in-out 0.5s infinite;
    margin: 0.2em;
  }
  #loader-3{
    animation: bounce 1.6s ease-in-out 0.9s infinite;
    margin: 0.2em;
  }
  
  @keyframes bounce{
    0%,  100%{
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0) rotate(0);
      
    }
  
    50% {
      -webkit-transform: translateY(-20px);
      -ms-transform: translateY(-20px);
      -o-transform: translateY(-20px);
      transform: translateY(-20px) rotate(360deg);
  /*     transform: matrix(1, 0, -1, 0, 0, 0); */
    }
  
  }`, "",{"version":3,"sources":["webpack://./src/css/coffee-loader.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;;IAEX,YAAY;IACZ,kBAAkB;;EAEpB;EACA;IACE,iBAAiB;IACjB,SAAS;EACX;EACA;IACE,gDAAgD;IAChD,aAAa;EACf;EACA;IACE,gDAAgD;IAChD,aAAa;EACf;EACA;IACE,gDAAgD;IAChD,aAAa;EACf;;EAEA;IACE;MACE,gCAAgC;MAChC,4BAA4B;MAC5B,2BAA2B;MAC3B,kCAAkC;;IAEpC;;IAEA;MACE,oCAAoC;MACpC,gCAAgC;MAChC,+BAA+B;MAC/B,2CAA2C;EAC/C,8CAA8C;IAC5C;;EAEF","sourcesContent":[".loader-holder {\n    width:100%;\n    height:100%;\n    \n    display:flex;\n    align-items:center;\n    \n  }\n  .loader {\n    position:relative;\n    z-index:1;\n  }\n  #loader-1{\n    animation: bounce 1.6s ease-in-out 0.1s infinite;\n    margin: 0.2em;\n  }\n  #loader-2{\n    animation: bounce 1.6s ease-in-out 0.5s infinite;\n    margin: 0.2em;\n  }\n  #loader-3{\n    animation: bounce 1.6s ease-in-out 0.9s infinite;\n    margin: 0.2em;\n  }\n  \n  @keyframes bounce{\n    0%,  100%{\n      -webkit-transform: translateY(0);\n      -ms-transform: translateY(0);\n      -o-transform: translateY(0);\n      transform: translateY(0) rotate(0);\n      \n    }\n  \n    50% {\n      -webkit-transform: translateY(-20px);\n      -ms-transform: translateY(-20px);\n      -o-transform: translateY(-20px);\n      transform: translateY(-20px) rotate(360deg);\n  /*     transform: matrix(1, 0, -1, 0, 0, 0); */\n    }\n  \n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
