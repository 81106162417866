// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-dash {
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    vertical-align: middle;
    flex: 0 !important;
  }

.dislike-btn:hover{
  color:red;
cursor:pointer;
}

.like-btn{
  padding-right: 1em;
}
.like-btn:hover{
  color:green;
  cursor:pointer;
}

.space-buttons{
  padding-left: 4em;
}

.btn-filter{
  font-size: small;
  width: 8em;
  margin:0.2em;
  border-color: rgba(178, 34, 33,0.1) !important;
}

.btn-new-req{
  font-size: small;
  margin-right:0.3em;

  background-color: black !important;
  color: rgb(255, 255, 255) !important;
}

.btn-new-req:hover{
  background-color: rgb(78, 73, 73) !important;
  color: rgb(255, 255, 255) !important;
}`, "",{"version":3,"sources":["webpack://./src/css/buttons.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,2BAA2B;IAC3B,sBAAsB;IACtB,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;EACpB;;AAEF;EACE,SAAS;AACX,cAAc;AACd;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,YAAY;EACZ,8CAA8C;AAChD;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;;EAElB,kCAAkC;EAClC,oCAAoC;AACtC;;AAEA;EACE,4CAA4C;EAC5C,oCAAoC;AACtC","sourcesContent":[".btn-dash {\n    background-color: white;\n    border: 1px solid lightgrey;\n    border-radius: 0.25rem;\n    padding: 0.375rem 0.75rem;\n    vertical-align: middle;\n    flex: 0 !important;\n  }\n\n.dislike-btn:hover{\n  color:red;\ncursor:pointer;\n}\n\n.like-btn{\n  padding-right: 1em;\n}\n.like-btn:hover{\n  color:green;\n  cursor:pointer;\n}\n\n.space-buttons{\n  padding-left: 4em;\n}\n\n.btn-filter{\n  font-size: small;\n  width: 8em;\n  margin:0.2em;\n  border-color: rgba(178, 34, 33,0.1) !important;\n}\n\n.btn-new-req{\n  font-size: small;\n  margin-right:0.3em;\n\n  background-color: black !important;\n  color: rgb(255, 255, 255) !important;\n}\n\n.btn-new-req:hover{\n  background-color: rgb(78, 73, 73) !important;\n  color: rgb(255, 255, 255) !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
