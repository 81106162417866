import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../css/style.css';
import { PageContext } from '../../Context/pageContext';
import { Layout, Menu } from 'antd';
import {
    DatabaseOutlined,
    EyeOutlined,
    FileSearchOutlined,
    FormOutlined,
    PieChartOutlined,
} from '@ant-design/icons';
import Cookies from 'universal-cookie';
import { findMaxKeyAndIncrement } from './utils';
import { resetChat } from '../../APIs/api_genAI';


const SideBar = () => {
    const cookies = new Cookies();
    const navigate = useNavigate();

    const { setLlmResponse } = useContext(PageContext);
    const { setGeneratedQuery } = useContext(PageContext);
    const { setLastPrompt } = useContext(PageContext);
    const { setDataMode } = useContext(PageContext);
    const { setImageMode } = useContext(PageContext);
    const { setLookerMode } = useContext(PageContext);
    const { setDocumentaleMode } = useContext(PageContext);
    const { chatHistory, setChatHistory } = useContext(PageContext);
    const { chatMessagesData, setChatMessagesData } = useContext(PageContext);
    const { chatList } = useContext(PageContext);
    const [collapsed, setCollapsed] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState('1');
    const { Sider } = Layout;

    function addChat() {
        let newKey = findMaxKeyAndIncrement(chatHistory);
        let tmp_label = "Chat_" + (newKey - 100);

        chatHistory.push({ key: String(newKey), label: tmp_label, chat:  chatMessagesData});
        setChatHistory(chatHistory);
    }

    useEffect(() => {
        let state = cookies.get("state")
        if (state)
            setSelectedMenu(state["selectedMenu"]);
        else
            setSelectedMenu("100");
    }, [])

    function getItem(label, key, icon, children) {
        return {
            key,
            icon,
            children,
            label,
        };
    }

    const resetChatAndClearState = () => {
        try {
             resetChat();
            console.log("here")
            setChatMessagesData([]);  // Clear the messages state
            setChatHistory([]);       // Clear chat history
            setLlmResponse(undefined);  // Reset any other relevant state
            setGeneratedQuery(undefined);
            setLastPrompt(undefined);
        } catch (error) {
            console.error("Failed to reset chat:", error);
        }
    };

    const items = [
        getItem('Chiedi al DB', '1', <DatabaseOutlined />,
        [
            getItem(<Link key="data-gen" to="/data-gen" className="nav-link" >Nuova richiesta</Link>, "100",<FormOutlined />),
            /*getItem('Cronologia', 'sub1',null, chatHistory)*/
        ],
        ),
        getItem(<Link key="docu-gen" to="/docu-gen" className="nav-link">Document Search</Link>, '5', <FileSearchOutlined />),
        getItem(<Link key="image-gen" to="/image-gen" className="nav-link">Image Insight</Link>, '6', <EyeOutlined />),
        getItem('Looker', '2', <PieChartOutlined />,
            [
                getItem(<Link key="looker" to="/looker" className="nav-link">Visualizza</Link>, '3'),
            ]
        ),
    ];

    const itemsCrono = [
        getItem('Chiedi al DB', '1', <FileSearchOutlined />,
        [
            getItem(<Link key="data-gen" to="/data-gen" className="nav-link">Nuova richiesta</Link>, "100",<FormOutlined />),
            getItem('Cronologia', 'sub1',null, chatHistory)
        ],
        ),
        getItem(<Link key="docu-gen" to="/docu-gen" className="nav-link">Documentale</Link>, '5', <FileSearchOutlined />),
        getItem(<Link key="image-gen" to="/image-gen" className="nav-link">Image Insight</Link>, '6', <FileSearchOutlined />),
        getItem('Looker', '2', <PieChartOutlined />,
            [
                getItem(<Link key="looker" to="/looker" className="nav-link">Visualizza</Link>, '3'),
                getItem('Modifica', '4'),
            ]
        ),
    ];

    function getQuestionFromKey(keyToSearch) {
        const foundItem = chatHistory.find(item => item.key === keyToSearch);

        if (foundItem) {
            return foundItem;
        } else {
            return 'Label not found';
        }
    }
    function handleMode(e) {
        console.log(e.key);

        if (parseInt(e.key, 10) == 100) {
            resetChatAndClearState();

            setDataMode(true);
            setImageMode(false);
            setDocumentaleMode(false);
            setLookerMode(false);
            setSelectedMenu('100');

            //addChat();
            setChatMessagesData([]);
            cookies.set("state", { dataMode: true, imageMode: false, documentaleMode: false, lookerMode: false, selectedMenu: '100' }, { path: '/' });

            setLlmResponse(undefined);
            setLlmResponse(undefined);
            setGeneratedQuery(undefined);
            setLastPrompt(undefined);
        }

        if (parseInt(e.key, 10) == 1) {
            setDataMode(true);
            setImageMode(false);
            setDocumentaleMode(false);
            setLookerMode(false);
            setSelectedMenu('1');
            cookies.set("state", { dataMode: true, imageMode: false, documentaleMode: false, lookerMode: false, selectedMenu: '1' }, { path: '/' });
        }

        if (parseInt(e.key, 10) == 3) {

            window.open("https://illypoc.cloud.looker.com/login", "_blank");
        }

        if (parseInt(e.key, 10) == 5) {
            setDataMode(false);
            setImageMode(false);
            setDocumentaleMode(true);
            setLookerMode(false);
            setSelectedMenu('5');
            cookies.set("state", { dataMode: false, imageMode: false, documentaleMode: true, lookerMode: false, selectedMenu: '5' }, { path: '/' });
        }

        if (parseInt(e.key, 10) == 6) {
            setDataMode(false);
            setImageMode(true);
            setDocumentaleMode(false);
            setLookerMode(false);

            setSelectedMenu('6');
            cookies.set("state", { dataMode: false, imageMode: true, documentaleMode: false, lookerMode: false, selectedMenu: '6' }, { path: '/' });
        }

        if (parseInt(e.key, 10) == 4) {
            setDataMode(false);
            setImageMode(false);
            setDocumentaleMode(false);
            setLookerMode(false);
            setSelectedMenu('4');
            cookies.set("state", { dataMode: false, imageMode: false, documentaleMode: false, lookerMode: false, selectedMenu: '4' }, { path: '/' });

            window.open("https://illypoc.cloud.looker.com/login", "_blank");
        }

        if (parseInt(e.key, 10) > 100) {
            navigate('/data-gen');

            let item = getQuestionFromKey(e.key);
            console.log(item);
            setDataMode(true);
            setImageMode(false);
            setDocumentaleMode(false);
            setSelectedMenu('1');
            cookies.set("state", { dataMode: true, imageMode: false, documentaleMode: false, selectedMenu: '1' }, { path: '/' });

            setChatMessagesData(item.chat);

        }

    }

    return (
        <>

        <Sider className='sidebar-custom' theme="light" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div style={{ display: 'flex', justifyContent: 'center', top: '0', height: '1vh', background:'rgb(178, 34, 33)'}} ></div>
            <Menu theme="light" defaultSelectedKeys={[selectedMenu]} selectedKeys={[selectedMenu]} mode="inline" items={items/*chatList.length > 0 ? itemsCrono : items*/} onClick={handleMode} />
        </Sider>
        </>
    );
};
export default SideBar;