import React from 'react';
import logo from '../../img/illy-logo.png';

export default function Navbar() {
  return (
    <>
    <div style={{ display: 'flex', justifyContent: 'center', top: '0', height: '1vh', background:'rgb(178, 34, 33)'}} ></div>
    <div style={{ display: 'flex', justifyContent: 'center', padding: '1em', top: '0' }}>
      <img src={logo} alt="Illy AI" style={{ maxWidth: '8vw', maxHeight: '8vh' }} />
    </div>
</>
  );
}
