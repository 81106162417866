import React, { useContext, useState } from 'react';
import { Input } from 'antd';
import { getDocumentInsight, getInsight } from '../../APIs/api_genAI';
import { PageContext } from '../../Context/pageContext';
import { cleanString } from '../../Pages/utils';
import { SendOutlined } from '@ant-design/icons';

const { Search } = Input;

function CustomFooter() {
    const [message, setMessage] = useState('');
    const { setLlmResponse } = useContext(PageContext);
    const { setGeneratedQuery } = useContext(PageContext);
    const { setLastPrompt } = useContext(PageContext);
    const { loadingResponse, setLoadingResponse } = useContext(PageContext);
    const { documentaleMode } = useContext(PageContext);
    const { setLlmResponseDocumentale } = useContext(PageContext);
    const { setLastPromptDocumentale } = useContext(PageContext);
    const { setReferencedResponse } = useContext(PageContext);
    const { chatMessagesData, setChatMessagesData } = useContext(PageContext);
    const { activeFilters } = useContext(PageContext);


    async function requestData() {
        setLoadingResponse(true);
        setLlmResponse(undefined);
        setGeneratedQuery(undefined);

        let temp_chat = chatMessagesData
        temp_chat.push({ "human": { "msg": message } })
        setChatMessagesData(temp_chat);

        setLastPrompt(message);
        setMessage(''); // Clear the input field after submission

        let data = await getInsight(message);

        if (data && data['text_to_sql_payload']) {
            let jsonResult = undefined;
            let msg = undefined;

            if (data['text_to_sql_payload']['bigquery_data'] != "")
                jsonResult = JSON.parse(data['text_to_sql_payload']['bigquery_data']);

            if (data['human_readable_response'] != "")
                msg = data['human_readable_response'];
            else
                msg = data['text_to_sql_payload']['llm_response'];

            console.log(jsonResult)

            let temp_chat = chatMessagesData
            temp_chat.push({
                "ai": {
                    "msg": msg,
                    "query": data['text_to_sql_payload']['generated_query'],
                    "jsonTable": jsonResult
                }
            })
            setChatMessagesData(temp_chat);
        }
        else {
            setLlmResponse("Non è stato possibile recuperare l'informazione.")
            setGeneratedQuery(undefined);
            let temp_chat = chatMessagesData
            temp_chat.push({
                "ai": {
                    "msg": "Non è stato possibile recuperare l'informazione.",
                    "query": undefined
                }
            })
            setChatMessagesData(temp_chat);
        }
        setLoadingResponse(false);
    }

    function handleKeySubmit(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            requestData();
        }
    }

    return (
        <>
            <div className="chat-input-container">
                <div className="chat-input-container-form">

                    <Search
                        className='input-footer noHover'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder={"Chiedi informazioni all'esperto"}
                        onKeyDown={handleKeySubmit}
                        disabled={loadingResponse}
                        enterButton={<SendOutlined disabled={!message} onClick={() => requestData()} />}
                        tabIndex={0}
                    />
                </div>
            </div>
        </>
    );
}

export default CustomFooter;
