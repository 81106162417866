import React, { useContext, useState } from "react";
import { PageContext } from "../../Context/pageContext";
import { getImageInsight } from '../../APIs/api_genAI';
import { Button, Input } from 'antd';

import { SendOutlined, UploadOutlined } from "@ant-design/icons";

const { Search } = Input;

export default function ImageFooter() {
    const { file, setFile } = useContext(PageContext);
    const [message, setMessage] = useState('');
    const { loadingResponse, setLoadingResponse } = useContext(PageContext);
    const { setLlmResponseImage } = useContext(PageContext);
    const { chatHistoryImage, setChatHistoryImage } = useContext(PageContext);


    async function handleKeySubmit(e) {
        if (e.key === 'Enter') {
            e.preventDefault();  // Prevent the default behavior (inserting a newline character)
            console.log('Submitted message:', message);

            if (!file) {
                console.error("No file selected");
                return;
            }

            try {
                requestImageInsight();
            } catch (error) {
                console.error("Error:", error);
            }
        }
    }

    async function requestImageInsight() {
        setLoadingResponse(true);

        setMessage(''); // Clear the input field after submission
        let temp_chat = chatHistoryImage
        temp_chat.push({ "human": { "msg": message } })

        let data = await getImageInsight(file, message);
        console.log(data)
        if (data && data['llm_response']) {
            setLlmResponseImage(data['llm_response']);
            temp_chat.push({
                "ai": { "msg": data['llm_response'] }
            })
        }
        else {
            setLlmResponseImage(undefined);
            temp_chat.push({
                "ai": {
                    "msg": "Non è stato possibile recuperare l'informazione.",
                }
            })
        }
        setChatHistoryImage(temp_chat);
        setLoadingResponse(false);
    }

    function handleUpload(e) {
        console.log(e.target.files);
        setFile(e.target.files[0]);
    }

    return (
        <>
            <div className="chat-input-container">
                <div className="chat-input-container-form">
                    <Button className='upload-footer' icon={<UploadOutlined />}>
                        <input
                            type="file"
                            onChange={handleUpload}
                            style={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                right: 0,
                                bottom: 0,
                                opacity: 0,
                                cursor: 'pointer'
                            }}
                        />
                    </Button>
                    <Search
                        className='input-footer noHover'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder={"Chiedi informazioni all'esperto"}
                        onKeyDown={handleKeySubmit}
                        disabled={loadingResponse}
                        enterButton={<SendOutlined disabled={!message} onClick={() => requestImageInsight()} />}
                        tabIndex={0}
                    />
                </div>
            </div>
        </>
    );
}
