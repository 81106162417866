// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  position: absolute;
  bottom: 0;
  background-color: black;
  height: 10.5rem;         
}

.chat-input-container {
  bottom: 0;
  width: 100%;
  padding-bottom: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-input-container-form {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-input-container-button {
  font-size: x-large;
  margin-left: 1em;
}

.chat-input-container-button:hover {
  color: rgb(144, 154, 144);
  cursor: pointer !important;
}

.upload-footer{
  height: 2em !important;
  max-height: 2em !important;
  margin-right: 0.5em;
}

.upload-footer:hover{
  color: rgb(178, 34, 33) !important;
}

.input-footer{
  height: 2em !important;
  max-height: 2em !important;
}

.loader-on-top {
  margin-left: auto; /* Pushes the loader to the right */
}

.btn-continua{
  margin-left: 0.5em;
}

.btn-continua:hover{
  color: rgb(178, 34, 33) !important;
}`, "",{"version":3,"sources":["webpack://./src/css/footer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,0BAA0B;AAC5B;;AAEA;EACE,sBAAsB;EACtB,0BAA0B;EAC1B,mBAAmB;AACrB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,sBAAsB;EACtB,0BAA0B;AAC5B;;AAEA;EACE,iBAAiB,EAAE,mCAAmC;AACxD;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".footer {\n  position: absolute;\n  bottom: 0;\n  background-color: black;\n  height: 10.5rem;         \n}\n\n.chat-input-container {\n  bottom: 0;\n  width: 100%;\n  padding-bottom: 2em;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.chat-input-container-form {\n  width: 50%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.chat-input-container-button {\n  font-size: x-large;\n  margin-left: 1em;\n}\n\n.chat-input-container-button:hover {\n  color: rgb(144, 154, 144);\n  cursor: pointer !important;\n}\n\n.upload-footer{\n  height: 2em !important;\n  max-height: 2em !important;\n  margin-right: 0.5em;\n}\n\n.upload-footer:hover{\n  color: rgb(178, 34, 33) !important;\n}\n\n.input-footer{\n  height: 2em !important;\n  max-height: 2em !important;\n}\n\n.loader-on-top {\n  margin-left: auto; /* Pushes the loader to the right */\n}\n\n.btn-continua{\n  margin-left: 0.5em;\n}\n\n.btn-continua:hover{\n  color: rgb(178, 34, 33) !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
