import React, { useContext, useEffect, useRef, useState } from "react";
import { CodeOutlined, DislikeOutlined, LikeOutlined } from '@ant-design/icons';
import { Tooltip, message, Popconfirm } from 'antd';
import { Robot, UserCircle } from "@phosphor-icons/react";

import { ModalCode } from "./modalCode";
import { PageContext } from "../../Context/pageContext";
import TableViewer from 'react-js-table-with-csv-dl';
import { ExportToCSV } from "../../Components/Download/downloadButton";
import CoffeLoader from "../../Components/Loader/coffeLoader";
import { removeMessage } from "../../APIs/api_genAI";
import Typewriter from "../../Components/Typewriter/typewriter";

export default function DataGenChat() {
    const delay = 30;
    const { isModalCodeOpen, setIsModalCodeOpen } = useContext(PageContext);
    const { loadingResponse } = useContext(PageContext);
    const { chatMessagesData } = useContext(PageContext);

    const chatEndRef = useRef(null);
    const [currentModalIndex, setCurrentModalIndex] = useState(null);

    const handleCodeModal = (index) => {
        console.log(chatMessagesData[index].ai.query)
        setCurrentModalIndex(index);
        setIsModalCodeOpen(true);
    };

    async function confirm(e) {
        try {
            await removeMessage("index").then(() => { message.success('É stata inviato il tuo feedback') });
        } catch {
            console.error("Error: ", e);
        }
    }

    function cancel(e) {
        console.log(e);
    }

    useEffect(() => {
        // Scroll to the bottom of chat messages on update
        chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }, [loadingResponse]);

    return (
        <>
        <div className="container mt-5 scrollable-div">
            <div className="d-flex flex-row justify-content-start">
                <div className="icon-ai"><Robot size={22} /></div>
                <div className="group-background-ai group-background">
                    <Typewriter text={"Ciao che cosa vuoi chiedermi?"} delay={delay} />
                </div>
            </div>

            {chatMessagesData?.length > 0 ? (
                <>
                    {chatMessagesData.map((message, index) => (
                        <React.Fragment key={index}>
                            {/* Verifica se il messaggio è dell'utente o AI */}
                            {message.human ? (
                                // Messaggio dell'utente
                                <div
                                    className={`d-flex flex-row justify-content-end mt-3`}
                                >
                                    <div className={`group-background-human`}>
                                        <Typewriter text={message.human.msg} delay={0} />
                                    </div>
                                    <div className="icon-human"><UserCircle size={22} /></div>
                                </div>
                            ) : (
                                // Messaggio AI
                                <>
                                    {/* Primo div per il testo principale AI */}
                                    <div
                                        className={`d-flex flex-row justify-content-start mt-3`}
                                    >
                                        {message.ai && <div className="icon-ai"><Robot size={22} /></div>}
                                        <div className={`group-background-ai group-background`}>
                                            <Typewriter text={message.ai.msg} delay={delay} />
                                        </div>
                                    </div>

                                    {/* Secondo div per la tabella JSON e pulsanti di feedback */}
                                    {message.ai.jsonTable && message.ai.jsonTable.length > 0 && (
                                        <div
                                            className={`d-flex flex-row justify-content-start mt-3`}
                                        >
                                            {message.ai && <div className="icon-ai"><Robot size={22} /></div>}
                                            <div className={`group-background-ai group-background`}>
                                            <div className="flex-row d-flex justify-content-end">
                                                {message.ai.query && (
                                                    <Tooltip title="Codice generato">
                                                        <CodeOutlined
                                                            className="code-icon"
                                                            onClick={() => handleCodeModal(index)}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </div>
                                                <TableViewer
                                                    key={`table-${index}`}
                                                    content={message.ai.jsonTable}
                                                    headers={Object.keys(message.ai.jsonTable[0])}
                                                    pagination={15}
                                                    minHeight={0}
                                                    maxHeight={400}
                                                    reverseLineNumber={true}
                                                />
                                                {index === chatMessagesData.length - 1 && (
                                                    <div className="flex-row d-flex">
                                                        <div className="d-flex justify-content-start flex-grow-1">
                                                            <LikeOutlined className="like-btn mt-2" />
                                                            <Popconfirm
                                                                title="Feedback dell'utente"
                                                                description="Sicuro che vuoi segnalare questa risposta come errata?"
                                                                onConfirm={confirm}
                                                                onCancel={cancel}
                                                                okText="Si"
                                                                cancelText="No"
                                                            >
                                                                <DislikeOutlined className="dislike-btn mt-2" title={"Risposta sbagliata"} />
                                                            </Popconfirm>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="flex-row d-flex justify-content-end">
                                                    <ExportToCSV key={`exportCSV-${index}`} data={message.ai.msg} hidden={!message.ai.query} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </React.Fragment>
                    ))}

                    {loadingResponse && (
                        <div className="group-background">
                            <div className={`d-flex flex-row justify-content-start mt-3`}>
                                <CoffeLoader ref={chatEndRef} />
                            </div>
                        </div>
                    )}
                </>
            ) : null}
            <div className="p-2" ref={chatEndRef} />
        </div>

        <ModalCode
            key={`modal-code`}
            generated_code={currentModalIndex ? chatMessagesData[currentModalIndex].ai.query : null}
            visible={isModalCodeOpen}
            onClose={() => setIsModalCodeOpen(false)}
        />
    </>

    );
}
