import React, { useContext, useState } from 'react';
import { Input } from 'antd';
import { getDocumentInsight } from '../../APIs/api_genAI';
import { PageContext } from '../../Context/pageContext';
import { cleanString } from '../../Pages/utils';
import { SendOutlined } from '@ant-design/icons';

const { Search } = Input;

function DocuFooter() {
    const [message, setMessage] = useState('');
    const { setGeneratedQuery } = useContext(PageContext);
    const { loadingResponse, setLoadingResponse } = useContext(PageContext);
    const { setLlmResponseDocumentale } = useContext(PageContext);
    const { setLastPromptDocumentale } = useContext(PageContext);
    const { setReferencedResponse } = useContext(PageContext);
    const { activeFilters } = useContext(PageContext);
    const {chatHistoryDocumentale, setChatHistoryDocumentale} = useContext(PageContext);

    async function requestDocumentale() {
        setLoadingResponse(true);
        setLlmResponseDocumentale(undefined);
        setGeneratedQuery(undefined);

        setLastPromptDocumentale(message);

        let temp_chat = chatHistoryDocumentale
        temp_chat.push({ "human": { "msg": message } })

        setMessage('');

        let data = await getDocumentInsight(message, activeFilters);

        if (data){
            const documentsArray = Object.entries(data['response']["documents"]).map(([key, document]) => ({
                number: key,
                folder: document.folder,
                page: document.page,
                link: document.link,
                preview: document && document.preview ? document.preview : "Preview not available."
            }));

            setLlmResponseDocumentale(data);
            temp_chat.push({
                "ai": { "msg": data['response']["summary"],
                    "documents": documentsArray
                }
            })
        }
        else{
            setLlmResponseDocumentale(undefined);
            temp_chat.push({
                "ai": {
                    "msg": "Non è stato possibile recuperare l'informazione.",
                }
            })
        }

        setChatHistoryDocumentale(temp_chat);
        setLoadingResponse(false);
    }

    function handleKeySubmit(e) {
        if (e.key === 'Enter') {
            e.preventDefault();  // Prevent the default behavior (inserting a newline character)
            console.log('Submitted message:', message);

            setReferencedResponse(undefined);
            requestDocumentale();
            setMessage(undefined);

            console.log("updated history");
        }
    }

    return (
        <>
            <div className="chat-input-container">
                <div className="chat-input-container-form">
                <Search
                        className='input-footer noHover'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder={"Chiedi informazioni all'esperto"}
                        onKeyDown={handleKeySubmit}
                        disabled={loadingResponse}
                        enterButton={<SendOutlined disabled={!message} onClick={() => requestDocumentale()} />}
                        tabIndex={0}
                    />
                </div>
            </div>
        </>
    );
}

export default DocuFooter;
