import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const PageContext = createContext();

function PageContextProvider(props) {
  const [dataMode, setDataMode] = useState(false);
  const [imageMode, setImageMode] = useState(false);
  const [lookerMode, setLookerMode] = useState(false);
  const [documentaleMode, setDocumentaleMode] = useState(true);
  const [isModalCodeOpen, setIsModalCodeOpen] = useState(false);
  const [lastPrompt, setLastPrompt] = useState(undefined);
  const [lastPromptDocumentale, setLastPromptDocumentale] = useState(undefined);
  const [llmResponse, setLlmResponse] = useState(undefined);
  const [llmResponseDocumentale, setLlmResponseDocumentale] = useState(undefined);
  const [generatedQuery, setGeneratedQuery] = useState(undefined);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [isModalKPIOpen, setIsModalKPIOpen] = useState(false);
  const [isModalDocumentaleOpen, setIsModalDocumentaleOpen] = useState(false);
  const [referencedResponse, setReferencedResponse] = useState(undefined);
  const [chatMessagesData, setChatMessagesData] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [file, setFile] = useState(undefined);
  const [llmResponseImage, setLlmResponseImage] = useState(undefined);
  const [chatHistoryImage, setChatHistoryImage] = useState([]);
  const [chatHistoryDocumentale, setChatHistoryDocumentale] = useState([]);

  // Return the context provider with the current object and setter function
  return (
    <PageContext.Provider
      value={{
        dataMode, setDataMode,
        imageMode, setImageMode,
        lookerMode, setLookerMode,
        documentaleMode, setDocumentaleMode,
        isModalCodeOpen, setIsModalCodeOpen,
        llmResponse, setLlmResponse,
        llmResponseDocumentale, setLlmResponseDocumentale,
        generatedQuery, setGeneratedQuery,
        lastPrompt, setLastPrompt,
        lastPromptDocumentale, setLastPromptDocumentale,
        loadingResponse, setLoadingResponse,
        chatList, setChatList,
        isModalKPIOpen, setIsModalKPIOpen,
        isModalDocumentaleOpen, setIsModalDocumentaleOpen,
        referencedResponse, setReferencedResponse,
        chatMessagesData, setChatMessagesData,
        chatHistory, setChatHistory,
        activeFilters, setActiveFilters,
        file, setFile,
        llmResponseImage, setLlmResponseImage,
        chatHistoryImage, setChatHistoryImage,
        chatHistoryDocumentale, setChatHistoryDocumentale
      }}>
      {props.children}
    </PageContext.Provider>
  );
}

PageContextProvider.propTypes = {
  children: PropTypes.object
}

export default PageContextProvider